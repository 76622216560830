import React from 'react';
import { Box, Flex, Text, Image, Stack, Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const nsPic = require("../images/ns.jpg");
const iotpic = require("../images/iot.jpg");
const w3pic = require("../images/web3.jpg");
const cxLogo = require("../images/cnnex-logo.png");
const prlogo = require("../images/pickreel-logo_s2.png");
const hsLogo = require("../images/heasor-logo.png");

const AboutPage = () => {

    const nav = useNavigate();
    const handleClose = () => {
        nav("/");
    };

    return (
        <Flex
            direction={"column"}
            align="center"
            justify={"center"}
            minHeight={"100vh"}
            bgGradient={"linear(to-r, gray.800, blue.100)"}
            p={8}
        >
            <Helmet>
                <title>About Us - Cnnex Group</title>
                <meta name="description" content="Learn more about Cnnex Group, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
                <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
            </Helmet>
            <Button
                marginTop={"5px"}
                leftIcon={<CloseIcon boxSize={2}/>}
                position="fixed"
                top={150}
                // left="50%"
                // transform="translateX(-50%)"
                right={10}
                onClick={handleClose}
                aria-label="Close"
                colorScheme='orange'
                size={"sm"}
                fontSize={"12px"}
            >
                Close
            </Button>
            <Box
                bg={"white"}
                p={8}
                borderRadius={"lg"}
                boxShadow={"2xl"}
                maxWidth={"800px"}
                textAlign={"center"}
                mt={12}
            >

                <Stack spacing={3} >
                    <Text fontSize={"3xl"} fontWeight={"bold"} color={"teal.600"} >
                        About Cnnex
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        Cnnex Group, founded in 2017, is a network technology company driven by artificial intelligence (AI). The company focuses on cutting-edge research and applications in three major technological areas: network security, Internet of Things (IoT), and Web3.0 technologies. These three fields form the core of Cnnex's business, positioning it at the forefront of global technological innovation. Through its three flagship brands—Cnnex, Heasor Lab, and Pickreel—Cnnex strives to explore, innovate, and push the boundaries of AI applications.
                    </Text>
                    <Stack direction={"row"} spacing={4} mt={4} align={"center"} justify={"center"} marginBottom={"30px"}>
                        <Image src={cxLogo} alt="cnnex" boxSize="40px" marginRight={"40px"} />
                        <Image src={hsLogo} alt="heasor" boxSize="40px" marginRight={"40px"} />
                        <Image src={prlogo} alt="pickreel" boxSize="50px" />
                    </Stack>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={"gray.700"} >
                        Network Security Business
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        In the field of network security, Cnnex specializes in providing advanced technical solutions. The company is dedicated to the development of VPN (Virtual Private Network) technologies, offering users secure and reliable network connections. Furthermore, Cnnex has a strong foundation in end-to-end encrypted file transfer networks, ensuring that users' data is protected to the highest degree during transmission, regardless of the network environment. The core of these technologies lies in the use of advanced encryption algorithms and zero-knowledge encryption protocols, which make intercepted data unreadable, significantly enhancing users' privacy and security.                    
                    </Text>
                    <Image 
                        src={nsPic}
                        alt='About Cnnex'
                        borderRadius={"lg"}
                        boxShadow={"lg"}
                    />
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        Beyond encryption, Cnnex is also involved in the development of underlying network systems, particularly in creating more secure network infrastructures at the operating system and application levels. By integrating these deep-level technologies, the company provides both enterprise and individual users with comprehensive security measures, safeguarding against malware, cyber-attacks, and other potential threats. Additionally, the company leads in file security encryption technology, offering a range of encryption tools that ensure the safety and integrity of data during local storage, cloud storage, and cross-device sharing.
                    </Text>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={"gray.700"} >
                        IoT Business
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        In the Internet of Things (IoT) domain, Cnnex focuses on sensor technology development and AI-enabled healthcare IoT applications. The company's research and development teams have created a series of advanced sensor technologies that allow IoT devices to capture environmental changes and key human data with greater precision and intelligence. These sensors are not only applicable in smart homes but can also be widely used in industries such as healthcare, transportation, and agriculture, enhancing automation and data processing capabilities across different sectors.
                    </Text>
                    <Image 
                        src={iotpic}
                        alt='About Cnnex'
                        borderRadius={"lg"}
                        boxShadow={"lg"}
                    />
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        A key focus within Cnnex’s IoT business is health IoT technology. By integrating AI models, Cnnex has made significant strides in areas like remote healthcare and health monitoring. For example, the AI-powered health sensors developed by the company can monitor vital signs such as heart rate and blood oxygen levels in real-time, transmitting this data through IoT devices to the cloud for real-time analysis and medical intervention. This technology not only advances the intelligence of health monitoring systems but also fosters the development of personalized healthcare.
                    </Text>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={"gray.700"} >
                        Web3.0 Technology Business
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        As the era of Web3.0 approaches, Cnnex remains at the cutting edge of exploration in this field. The company combines AI models with video and image processing technologies, developing a series of innovative products and solutions. These technologies enhance the efficiency and quality of video and image capture, transmission, storage, and processing. Especially in the area of media content creation and editing, Cnnex’s technologies can automatically identify and optimize elements within images, enhancing visual effects while significantly reducing processing time.
                    </Text>    
                    <Image 
                        src={w3pic}
                        alt='About Cnnex'
                        borderRadius={"lg"}
                        boxShadow={"lg"}
                    />    
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        Additionally, Cnnex merges Web3.0 technologies with AI models to create various tools aimed at improving personal productivity in work and life. These tools, powered by deep learning algorithms, help users automate workflows, manage tasks, and plan their lives intelligently. For instance, the AI assistant developed by the company can generate daily schedules based on user behavior, prioritize tasks, and boost efficiency through intelligent reminders. By leveraging Web3.0’s decentralized and personalized features, Cnnex offers users more efficient and intelligent tools to enhance both work and life.            
                    </Text>    
                    <Text fontSize={"xl"} fontWeight={"bold"} color={"gray.700"} >
                        Global R&D Team and Technological Strength
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        Cnnex’s technological strength stems not only from its deep expertise in AI and network technology but also from its international R&D team. The company's core team of experts comprises professors, PhDs, and Masters from the U.S. and Hong Kong, with extensive academic backgrounds and practical experience in network security, AI algorithms, and IoT technologies. Cnnex has established R&D centers in the U.S., Hong Kong, and Singapore, leveraging a global perspective and collaboration to drive technological innovation and deliver cutting-edge solutions to its clients.
                    </Text> 
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                        The collaboration across international R&D teams enables Cnnex to rapidly respond to changing global market demands and incorporate the latest technological achievements into product development. This global layout not only enhances the company’s innovation capacity but also allows it to better address the technological challenges and market competition in different regions.
                    </Text>
                    <Text fontSize={"xl"} fontWeight={"bold"} color={"gray.700"} >
                        Company Mission and Vision
                    </Text>
                    <Text fontSize={"md"} color={"gray.700"} textAlign={"left"} lineHeight={"2.5"}>
                    Since its inception, Cnnex has adhered to the mission of “Making life safer, more efficient, and more enjoyable through AI and network technologies.” The company believes that as AI and network technologies continue to evolve, the future society will become more intelligent and personalized. Cnnex aims to drive this change through its innovations, providing users with a more secure and reliable network environment, smarter and more efficient tools for daily life and work, and more fun and creative digital experiences.
                    </Text>
                </Stack>

            </Box>
        </Flex>

    );
};

export default AboutPage;