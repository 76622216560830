import React from 'react';
import { Box, Flex, Text, Stack, Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// const logo = require("../images/pickreel-logo_s2.png");

const SPCPolicy = () => {
  const nav = useNavigate();

  const handleClose = () => {
    nav("/");
  };

  return (

    <Flex
      direction={"column"}
      align="center"
      justify={"center"}
      minHeight={"100vh"}
      bgGradient={"linear(to-r, gray.800, blue.100)"}
      p={8}
    >
      <Helmet>
          <title>Privacy Policy</title>
          <meta name="description" content="Learn more about Cnnex Group, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
          <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
      </Helmet>

      <Button
        marginTop={"5px"}
        leftIcon={<CloseIcon boxSize={2}/>}
        position="fixed"
        top={150}
        // left="50%"
        // transform="translateX(-50%)"
        right={10}
        onClick={handleClose}
        aria-label="Close"
        colorScheme='orange'
        size={"sm"}
        fontSize={"12px"}
      >
        Close
      </Button>
      <Box
        bg={"white"}
        p={8}
        borderRadius={"lg"}
        boxShadow={"2xl"}
        maxWidth={"98%"}
        textAlign={"center"}
        mt={12}
      >

        <Stack spacing={3} >
          {/* <Image src={logo} alt='logo' boxSize={"60px"} onClick={handleClose} cursor={"pointer"} /> */}
          <Text fontSize={"3xl"} fontWeight={"bold"} color={"teal.600"} >
            Privacy Policy 
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
             At Super Power Copy, your privacy and security are our top priorities. This privacy policy outlines how we handle user data and highlights our commitment to protecting your personal information.
          </Text>
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            1. No Data Collection 
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            is designed to operate entirely on your local device. We do not collect, store, or share any personal data, browsing history, or content accessed through our application. This means:
          </Text>         
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            No User Information Collection: We do not collect your name, email, or any personally identifiable information (PII).
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            No Usage Data Collection: We do not track or store information about your usage of the app, such as pages visited or content copied.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            No Cookies or Tracking Technology: does not use cookies or any tracking technologies to monitor your online behavior.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            2. Local Operations Only
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            runs exclusively on your local machine and interacts with websites directly through your browser. All processes related to removing copy restrictions occur locally, ensuring that no data is transmitted to external servers or third-party services.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            3. Third-Party Services
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Since does not collect any data, we also do not share any information with third-party services, analytics providers, or advertising platforms. Your interaction with the software remains private and secure on your device.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            4. Security
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            While we do not collect or process any data, we are committed to providing a secure application environment. is regularly updated to patch vulnerabilities and enhance security features to protect your local system.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            5. Changes to this Privacy Policy
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            We may update this privacy policy periodically. Any changes to the policy will be reflected on this page with an updated “Effective Date.” However, our core principle remains the same: we will never collect or share your data.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            6. Contact Us
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            If you have any questions or concerns about this privacy policy, feel free to contact us at service@cnnex.us
          </Text>   

          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            Your Privacy Matters!
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            ensures your browsing and data remain completely private by operating without any form of data collection or tracking.
          </Text>  
        </Stack>

      </Box>
    </Flex>

  );
};

export default SPCPolicy;