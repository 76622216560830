import './VideoList.css';
import { MdDownload } from "react-icons/md";
import { Button, HStack, Text, Box } from '@chakra-ui/react';
import { useState } from 'react';


const VideoList = ({ videoString }) => {
    const videoUrls = videoString.trim().split(',');
    // console.log(videoUrls)
    const [ downloadings, setDownloadings ] = useState(
        videoUrls.map(() => false) 
    );
    const [ progresses, setProgresses ] = useState(
        videoUrls.map(() => 0)
    );

    const handleDownloadPrfile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl
        link.download = "prvideo.mp4";
        link.target = '_blank';                 // Ensure it's downloaded, not opened in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDownload = async (fileUrl, index) => {
        if (fileUrl.includes("https://prfile.heasor.com/v/download")) {
            handleDownloadPrfile(fileUrl);
        } else {
            try {
                // setDownloading(true);
                setDownloadings((preDownloading) => {
                    const newDonwloading = [...preDownloading];
                    newDonwloading[index] = true;
                    return newDonwloading;
                })
                setProgresses( videoUrls.map(() => 0) );
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${(response).status}`);
                }
                const total = parseInt(response.headers.get(`Content-Length`), 10);
                const reader = response.body.getReader();
                var receivedLength = 0;
                const chunks = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    chunks.push(value);
                    receivedLength += value.length;

                    const prog = (receivedLength / total) * 100

                    setProgresses((preProgresses) => {
                        const newProg = [...preProgresses];
                        newProg[index] = prog;
                        return newProg;
                    });
                }

                // Combine all chunks into a single Uint8Array
                const blobArray = new Uint8Array(receivedLength);
                var position = 0;
                for (var chunk of chunks) {
                    blobArray.set(chunk, position);
                    position += chunk.length;
                }

                // Combine chunks into a blob
                const blob = new Blob([blobArray], {type: 'video/mp4'});
                const url = window.URL.createObjectURL(blob);
                const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace(/\..+/, '');

                // Create a temporary link for downloading the file
                const link = document.createElement('a');
                link.href = url;
                link.download = `prvideo_${timestamp}.mp4`;
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                // setDownloading(false);
                setDownloadings(videoUrls.map(() => false));

            } catch(error) {
                console.error("Error downloading file:", error);
                setDownloadings(videoUrls.map(() => false));
            }
        }
    }
    
    return (
        <div className="video-list">
        {videoUrls.map((url, index) => (
          <div key={index} className="video-frame">
            <Box width={"100%"} height={"90%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <video className="video" controls>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
                </video>
            </Box>

            <HStack spacing={5} width={"100%"} justifyContent={"left"} alignItems={"end"} height={"10%"} marginTop={"17px"}>
                <Button className="download-button" 
                        leftIcon={<MdDownload />} 
                        style={{backgroundColor: "#4CAF50"}}
                        fontSize={"14px"}
                        color={"white"}
                        onClick={() => handleDownload(url, index)}
                        isLoading={downloadings[index]}
                        >
                    Download
                </Button> 
                {
                    progresses[index] > 0 && <Text fontSize='xs'>Downloaded: {Math.round(progresses[index])}%</Text>
                }
            </HStack>
          </div>
        ))}
        </div>
    );
}

export default VideoList;