import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text } from '@chakra-ui/react';
import { MdEmail } from 'react-icons/md';

const EmailVerificationModal = ({ isOpen, onClose, onSendVerification, isLoading }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Email Verification Required</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Your email has not been verified. Please verify your email to continue.</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" leftIcon={<MdEmail />} mr={3} onClick={onSendVerification} isLoading={isLoading}>
                        Send Verification Email
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EmailVerificationModal;