import { Box, Button, Flex, Input, Stack, Text, Link, Image, Alert, AlertIcon, AlertTitle, InputGroup, IconButton, InputRightElement, useToast,Spinner } from '@chakra-ui/react';
import { MdLogin, MdVisibility, MdVisibilityOff, MdClose } from "react-icons/md"
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalData } from '../context/GlobalDataContext';
import { Turnstile } from '@marsidev/react-turnstile';
import EmailVerificationModal from './EmailVerificationModal';
import { Helmet } from 'react-helmet';


const logo = require("../images/pickreel-logo_s2.png");


const LoginPage = ({ setIsLoginPage, showSuccess, setAvatar }) => {
    const nav = useNavigate();
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ success, setSuccess ] = useState(showSuccess || false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    const { apiUrl, setIsLoggedIn } = useGlobalData();
    const [ isLoading, setIsLoading ] = useState(false);
    const toast = useToast();
    const [turnstileToken, setTurnstileToken] = useState(null);
    const turnstileRef = useRef(null); // 创建 Turnstile 组件的引用
    const [ isModalOpen, setIsModalOpen ] = useState(false); // 控制 Modal 显示状态
    const [ isSendLoading, setIsSendLoading ] = useState(false);
    const [ isForgotPassLoading, setIsForgotPassLoading ] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (showSuccess) {
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }
    }, [showSuccess]);

    // useEffect(() => {
    //     if (setAvatar && email) {
    //         retrieveAvatar();  // Only call when setAvatar is available
    //     }
    // // eslint-disable-next-line
    // }, [setAvatar, email]);  // Only runs when setAvatar or email changes


    const handleEmailBlur = () => {
        // setEmailTouched(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            setEmailError('Email is required');
        } else if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    }

    const handlePasswordBlur = () => {

        if (!password) {
            setPasswordError('Password is required');
        }  else {
            setPasswordError('');
        }
    }

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        if (!password) {
            setPasswordError('Password is required');
            return false;
        }
        if (!turnstileToken) {
            setEmailError('Please complete the CAPTCHA');
            return false;
        }
        setEmailError('');
        setPasswordError('');
        return true;
    }

    const handleForgotPassword = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            return
        }
        try {
            setIsForgotPassLoading(true)
            const response = await fetch(`${apiUrl}/req_email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "req-type": "rp",
                    "email": email,
                })
            });

            const data = await response.text();
            // console.log(data);

            if (response.ok) {
                toast({
                    title: "Password reset email sent",
                    description: "Check your email for further instructions.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                if (data.includes("1 minute before")) {
                    toast({
                        title: "Warn",
                        description: "Please wait at least 1 minute before making another reqeust.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to send password reset email",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }

            }
        } catch (error) {  
            console.log(error);
        } finally {
            setIsForgotPassLoading(false);
        }
    }

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const retrieveAvatar = async () => {
        try {
            const response = await fetch(`${apiUrl}/retrieve_avatar?em=${email}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.blob();
            const base64Data = await convertBlobToBase64(data);
            // console.log(data);
            setAvatar(base64Data);
            localStorage.setItem('pr-avatar', base64Data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSendVerification = async () => {
        try {
            setIsSendLoading(true);
            const response = await fetch(`${apiUrl}/req_email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "req-type": "ac",
                    "email": email,
                })
            });
            const data = await response.text();
            // console.log(data)

            if (response.ok) {
                toast({
                    title: "Password reset email sent",
                    description: "Check your email for further instructions.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setTimeout(() => setIsModalOpen(false), 5000);
            } else {
                if (data.includes("1 minute before")) {
                    toast({
                        title: "Warn",
                        description: "Please wait at least 1 minute before making another reqeust.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                    return
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to send password reset email",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    return
                }
            };
        } catch(error) {
            console.log(error);
        } finally {
            setIsSendLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            // console.log("Form is valid");
            setIsLoading(true);
            const credentials = `prubasicauth,${email},${password}`;
            const encodedCredentials = btoa(credentials);
            try {
                // console.log('reCAPTCHA token:', recaptchaToken);

                const response = await fetch(`${apiUrl}/sign_in`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        basic: encodedCredentials,
                        // recaptchaToken: recaptchaToken,
                        turnstileToken: turnstileToken // 添加 turnstileToken
                    }),
                });
                const data = await response.text();
                // console.log(data);
                if (data.includes("Incorrect email or password")) {
                    setEmailError('Invalid email or password');
                    turnstileRef.current.reset(); // 重置 Turnstile
                } else if (data.includes("wait at least")) {
                    // setEmailError('Please wait for at 30 seconds before making another request.')
                    toast({
                        title: "Warn",
                        description: "Please wait at least 30 seconds before making another reqeust.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                    turnstileRef.current.reset(); // 重置 Turnstile
                    return
                } else if (data.includes("CAPTCHA verification failed")) {
                    toast({
                        title: "Error",
                        description: "error CAPTCHA verifcaiton",
                        status: "error",
                        duration: 500,
                        isClosable:  true,
                    });
                    turnstileRef.current.reset(); // 重置 Turnstile
                    return
                } else if (data.includes("Email is not verified"))  {
                    setIsModalOpen(true);
                    turnstileRef.current.reset(); // 重置 Turnstile
                    return
                } else {
                    localStorage.setItem('pr-token', data);
                    localStorage.setItem('pr-email', email);
                    await retrieveAvatar();
                    setIsLoggedIn(true);
                    nav("/");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    }
    
    useEffect(() => {
        setIsLoginPage(true);
        return () => setIsLoginPage(false);
    }, [setIsLoginPage]);

    const handleTurnstileChange = (token) => {
        // setRecaptchaToken(token);
        setTurnstileToken(token);
    };


    return (
        <Flex
            height="100vh"
            align="center"
            justify="center"
            // bg="gray.50"
        >
            <Helmet>
                <title>Pickreel - Sign in</title>
                <meta name="description" content="Sign in join video downloading, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
                <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
            </Helmet>
            {success && (
                <Alert status="success" position="fixed" top="0" left="0" right="0" zIndex="1000">
                    <AlertIcon />
                    <AlertTitle>Registration successful!</AlertTitle>
                </Alert>
            )}  

            <Box
                bg="white"
                p={8}
                width={screenWidth > 768 ? "lg" : "90%"}
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                marginBottom={"100px"}
                position={"relative"}
            >
                <IconButton 
                    icon={<MdClose />}
                    onClick={() => nav("/")}
                    variant={"ghost"}
                    position={"absolute"}
                    top={2}
                    right={2}
                    aria-label='Close'
                />
                <Image src={logo} width="60px" cursor={"pointer"} onClick={() => {
                    nav("/");
                }} />
                <Stack spacing={4}>
                    <Text fontSize="2xl" fontWeight="bold">Sign in to Pickreel</Text>

                    <Box>
                        <Input placeholder="Email" 
                                type="email" 
                                size="lg" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleEmailBlur}
                                />
                        {emailError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{emailError}</Text>}
                    </Box>
                    <InputGroup size="lg">
                        <Input placeholder="Password" 
                                type={showPassword ? "text" : "password"} 
                                size="lg" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={handlePasswordBlur}
                        />
                        <InputRightElement  height={"100%"}>
                            <IconButton
                                icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                onClick={() => setShowPassword(!showPassword)}
                                variant="ghost"
                                aria-label="Toggle Password Visibility"
                                size="sm"
                                height={"1.75rem"}
                            />
                        </InputRightElement>
                    </InputGroup>

                    {passwordError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{passwordError}</Text>}

                    <Link onClick={!isForgotPassLoading? handleForgotPassword : null}>
                        Forgot your password?
                        {isForgotPassLoading && <Spinner size={"sm"} ml={2} />} 
                    </Link>


                    <Turnstile
                        siteKey='0x4AAAAAAAluzucTu63IzIuJ'
                        onSuccess={handleTurnstileChange} // 确保传递参数
                        ref={turnstileRef} // 绑定 Turnstile 组件的引用
                    />

                    <Stack spacing={4}>
                        <Flex justify="space-between">
                            <Link color="blue.500" onClick={() => {
                                nav("/signup");
                            }}>Sign up</Link>
                            <Button leftIcon={<MdLogin />} 
                                    colorScheme="green" size="sm" 
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    >Sign in</Button>
                        </Flex>
                    </Stack>
                    {/* <Button>Sign in</Button> */}
                </Stack>
                
            </Box>
            <EmailVerificationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSendVerification={handleSendVerification}
                isLoading={isSendLoading}
            />
        </Flex>
    )
}

export default LoginPage;