import React from 'react';
import { Box, Flex, Text, Stack, Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// const logo = require("../images/pickreel-logo_s2.png");

const TermsOfUse = () => {
  const nav = useNavigate();

  const handleClose = () => {
    nav("/");
  };

  return (

    <Flex
      direction={"column"}
      align="center"
      justify={"center"}
      minHeight={"100vh"}
      bgGradient={"linear(to-r, gray.800, blue.100)"}
      p={8}
    >
      <Helmet>
          <title>Prickreel - Terms of user</title>
          <meta name="description" content="Learn more about Cnnex Group, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
          <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
      </Helmet>

      <Button
        marginTop={"5px"}
        leftIcon={<CloseIcon boxSize={2}/>}
        position="fixed"
        top={150}
        // left="50%"
        // transform="translateX(-50%)"
        right={10}
        onClick={handleClose}
        aria-label="Close"
        colorScheme='orange'
        size={"sm"}
        fontSize={"12px"}
      >
        Close
      </Button>
      <Box
        bg={"white"}
        p={8}
        borderRadius={"lg"}
        boxShadow={"2xl"}
        maxWidth={"98%"}
        textAlign={"center"}
        mt={12}
      >

        <Stack spacing={3} >
          {/* <Image src={logo} alt='logo' boxSize={"60px"} onClick={handleClose} cursor={"pointer"} /> */}
          <Text fontSize={"3xl"} fontWeight={"bold"} color={"teal.600"} >
            Terms of Use
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Welcome to Pickreel.com (hereinafter referred to as “the Website”). Before using our video downloading tools, please carefully read and understand the following legal terms. By using this Website, you agree to be bound by these terms:         
          </Text>
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            1. Dispute Resolution
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            IF YOU OR WE HAVE ANY DISPUTE WITH OR CLAIM AGAINST THE OTHER (A “CLAIM”) ARISING OUT OF OR RELATING IN ANY WAY TO THE SERVICES OR THESE TERMS, AND THE CLAIM IS NOT RESOLVED BY CALLING OUR CUSTOMER SERVICE DEPARTMENT AT (769) 486-2551 AND BY FOLLOWING THE INFORMAL DISPUTE RESOLUTION PROCEDURE SET FORTH BELOW, YOU AND WE EACH AGREE TO RESOLVE SUCH DISPUTES THROUGH AN INDIVIDUAL BINDING ARBITRATION OR AN INDIVIDUAL ACTION IN SMALL CLAIMS COURT. Class arbitrations and class actions are not permitted, and a Claim may not be consolidated with any other person’s claim. You and we agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that you and we are each waiving the right to a trial by jury or to participate in a class action. You and we agree that any arbitrations between you and us will be subject to this Section 1 and not to any prior arbitration agreement you had with us. This Section 1 shall survive termination of these Terms or any subscription that you may have to any of the Services.          
          </Text>       
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.1	Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure
          </Text>   
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Before a party commences an arbitration or files a small claims court action with respect to a Claim, the party must first send to the other a written notice of dispute (“Notice”). A Notice from you to us must (1) be sent by certified mail and send email to legal@pickreel.com; (2) be addressed to: Cnnex Limited Company, 701 Tilery Street #12, Austin, Texas 78702, Attn: Legal Department (the “Notice Address”); (3) contain your name, address, and email address; (4) describe the nature and basis of your Claim; (5) if you are submitting the Notice, include any relevant facts regarding your use of the Services, including without limitation whether you have created an account; (6) specify the nature and basis of the specific relief sought, including the damages sought, if any, and a detailed calculation of them; and (7) include a personally signed statement from you (and not your counsel) verifying the accuracy of the contents of the Notice. The Notice must be individualized, meaning it can concern only your dispute and no other person’s dispute.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            After receipt of a completed Notice, the parties shall engage in a good faith effort to resolve the dispute for a period of 60 days (which can be extended by agreement). You and we agree that, after receipt of the completed Notice, the recipient may request an individualized telephone or video settlement conference (which can be held after the 60-day period) and both parties will personally attend (with counsel, if represented). You and we agree that the parties (and counsel, if represented) shall work cooperatively to schedule the conference at the earliest mutually convenient time and to seek to reach a resolution. If we and you do not reach an agreement to resolve the issues identified in the Notice within 60 days after the completed Notice is received (or a longer time if agreed to by the parties), you or we may commence an arbitration proceeding or a small claims court proceeding (if permitted by small claims court rules).
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Compliance with this Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure section is a condition precedent to initiating arbitration. Any applicable limitations period (including statute of limitations) and any filing fee deadlines shall be tolled while the parties engage in the informal dispute resolution procedures set forth in this Section 1.1. All of the requirements of the Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure are essential so that you and we have a meaningful opportunity to resolve disputes informally. If any aspect of these requirements has not been met, a court of competent jurisdiction may enjoin the filing or prosecution of an arbitration. In addition, unless prohibited by law, the arbitration administrator may not accept, administer, assess, or demand fees in connection with an arbitration that has been initiated without completion of the Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure. If the arbitration is already pending prior to the completion of the Mandatory Pre-Arbitration Notice and Information Dispute Resolution Procedure, the arbitration shall be administratively closed. Nothing in this paragraph limits the right of a party to seek damages for non-compliance with the Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure in arbitration.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.2	Arbitration Procedure
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Any such arbitration shall be governed by applicable rules of National Arbitration & Mediation (“NAM”) (including the Comprehensive Dispute Resolution Rules and Procedures and/or the Supplemental Rules for Mass Arbitration Filings, as applicable) (“NAM Rules”), as modified by this Section 1 (the “Arbitration Agreement”), and will be administered by NAM. (If NAM is unavailable or unwilling to do so, another arbitration provider shall be selected by the parties that will do so, or if the parties are unable to agree on an alternative administrator, by the court pursuant to 9 U.S.C. §5.) The NAM Rules are available online at www.NAMADR.com, by calling NAM, or by requesting them in writing at the Notice Address. You may obtain a form to initiate arbitration at: https://www.namadr.com/content/uploads/2020/09/Comprehensive-Demand-for-Arb-revised-9.18.19.pdf or by contacting NAM.
          </Text>   
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            You and we agree that the party initiating arbitration must submit a certification that they have complied with and completed the Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedure requirements referenced in Section 1.1 and that they are a party to the Arbitration Agreement enclosed with or attached to the demand for arbitration. The demand for arbitration and certification must be personally signed by the party initiating arbitration (and their counsel, if represented).
          </Text>  
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            All issues, including the scope and enforceability of this Arbitration Agreement, are for the arbitrator to decide. The arbitrator may consider but shall not be bound by rulings in other arbitrations involving different customers. At the conclusion of the arbitration proceeding, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based. The arbitrator’s decision is binding only between you and us and will not have any preclusive effect in another arbitration or proceeding that involves a different party. An arbitrator’s award that has been fully satisfied shall not be entered in any court.
          </Text>  
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            As in court, you and we agree that any counsel representing a party in arbitration certifies when initiating and proceeding in arbitration that they are complying with the requirements of Federal Rule of Civil Procedure 11(b), including certification that the claim or relief sought is neither frivolous nor brought for an improper purpose. The arbitrator is authorized to impose any sanctions under the NAM Rules, Federal Rule of Civil Procedure 11, or applicable federal or state law, against all appropriate represented parties and counsel.
          </Text>  
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Except as expressly provided in this Arbitration Agreement, the arbitrator may grant any remedy, relief, or outcome that the parties could have received in court, including awards of attorneys’ fees and costs, in accordance with applicable law. Unless otherwise provided by applicable law, the parties shall bear their own attorneys’ fees and costs in arbitration unless the arbitrator awards sanctions or finds that either the substance of the claim, the defense, or the relief sought is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            The payment of arbitration fees (the fees imposed by the arbitration administrator including filing, arbitrator, and hearing fees) will be governed by the applicable NAM Rules, unless you qualify for a fee waiver under applicable law.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            In circumstances in which the NAM Rules provide for an in-person hearing, such hearing will take place in the U.S. County (or parish) of your residence, or otherwise in Bellevue, Washington. If the Mass Filing process described in Section 1.7 is triggered, then the location of any hearing will be determined by the arbitrator.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
           1.3	Discovery During Arbitration
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            The parties shall each be limited to a maximum of one (1) fact witness deposition per side, unless the arbitrator determines that more depositions are warranted based on the totality of circumstances, including the amount in controversy, the complexity of the factual issues, the number of parties and the diversity of their interests, and whether any or all of the claims appear, on the basis of the pleadings, to have sufficient merit to justify the time and expense associated with the requested discovery. Document requests shall be limited to documents that are directly relevant to the matter(s) in dispute or to its outcome; shall be reasonably restricted in terms of time frame, subject matter and persons or entities to which the requests pertain; shall not include broad phraseology such as “all documents directly or indirectly related to”; and shall not be encumbered with extensive “definitions” or “instructions.” The Arbitrator may edit or limit the number of document requests based on the totality of circumstances, including the factors listed above.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Electronic discovery, if any, shall be limited as follows. Absent a showing of compelling need: (i) electronic documents shall only be produced from sources used in the ordinary course of business, and not from backup servers, tapes or other media; (ii) the production of electronic documents shall normally be made on the basis of generally available technology in a searchable format that is usable by the requesting party and convenient and economical for the producing party; (iii) the parties need not produce metadata, with the exception of header fields for email correspondence; (iv) the description of custodians from whom electronic documents may be collected should be narrowly tailored to include only those individuals whose electronic documents may reasonably be expected to contain evidence that is material to the dispute; and (v) where the costs and burdens of e-discovery are disproportionate to the nature of the dispute or to the amount in controversy, or to the relevance of the materials requested, the arbitrator may either deny such requests or order disclosure on the condition that the requesting party advance the reasonable cost of production to the other side, subject to the allocation of costs in the final award.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.4	Confidentiality
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Upon either party’s request, the arbitrator will issue an order requiring that confidential information of either party disclosed during the arbitration (whether in documents or orally) may not be used or disclosed except in connection with the arbitration or a proceeding to enforce the arbitration award and that any permitted court filing of confidential information must be done under seal to the furthest extent permitted by law.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.5	Offer of Settlement
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            In any arbitration between you and us, the defending party may, but is not obligated to, make a written settlement offer at any time before the evidentiary hearing or, if a dispositive motion is permitted, prior to the dispositive motion being granted. The amount or terms of any settlement offer may not be disclosed to the arbitrator until after the arbitrator issues an award on the claim. If the award is issued in the other party’s favor and is less than the defending party’s settlement offer or if the award is in the defending party’s favor, the other party must pay the defending party’s costs incurred after the offer was made, including any attorney’s fees. If any applicable statute or case law prohibits the shifting of costs incurred in the arbitration, then the offer in this provision shall serve to cease the accumulation of any costs to which the party bringing the claim may be entitled for the cause of action under which it is suing.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.6	Mass Filing
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            If, at any time, 25 or more claimants (including you) submit Notices or seek to file demands for arbitration raising similar claims against the other party or related parties by the same or coordinated counsel or entities, consistent with the definition and criteria of Mass Filings (“Mass Filing”) set forth in NAM’s Mass Filing Supplemental Dispute Resolution Rules and Procedures (“NAM’s Mass Filing Rules,” available at https://www.namadr.com/resources/rules-fees-forms/), you and we agree that the additional procedures set forth below shall apply. The parties agree that throughout this process, their counsel shall meet and confer to discuss modifications to these procedures based on the particular needs of the Mass Filing. The parties acknowledge and agree that by electing to participate in a Mass Filing, the adjudication of their dispute might be delayed. Any applicable limitations period (including statute of limitations) and any filing fee deadlines shall be tolled beginning when the Mandatory Pre-Arbitration Notice and Informal Dispute Resolution Procedures are initiated, so long as the pre-arbitration Notice complies with the requirements in Section 1.1, until your Claim is selected to proceed as part of a staged process or is settled, withdrawn, otherwise resolved, or opted out of arbitration.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Stage One: Counsel for the claimants and counsel for Cnnex Limited Company  shall each select 25 claims per side (50 claims total) to be filed and to proceed in individual arbitrations as part of a staged process. Each of these individual arbitrations shall be assigned to a different, single arbitrator unless the parties agree otherwise in writing. Any remaining claims shall not be filed or be deemed filed in arbitration, nor shall any arbitration fees be assessed in connection with those claims unless and until they are selected to be filed in individual arbitration proceedings as part of a staged process. After this initial set of staged proceedings is completed, the parties shall promptly engage in a global mediation session of all remaining claims with a retired federal or state court judge and Cnnex Limited Company shall pay the mediator’s fee.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Stage Two: If the remaining claims are not resolved at this time, counsel for the claimants and counsel for Cnnex Limited Company shall each select 50 claims per side (100 claims total) to be filed and to proceed in individual arbitrations as part of a second staged process, subject to any procedural changes the parties agreed to in writing. Each of these individual arbitrations shall be assigned to a different, single arbitrator unless the parties agree otherwise in writing. Any remaining claims shall not be filed or be deemed filed in arbitration, nor shall any arbitration fees be assessed in connection with those claims unless and until they are selected to be filed in individual arbitration proceedings as part of a staged process. After this second set of staged proceedings is completed, the parties shall promptly engage in a global mediation session of all remaining claims with a retired federal or state court judge and Cnnex Limited Company shall pay the mediator’s fee.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Stage Three: If the remaining claims are not resolved at this time, counsel for the claimants and counsel for Cnnex Limited Company shall each select 100 claims per side (200 claims total) to be filed and to proceed in individual arbitrations as part of a third staged process, subject to any procedural changes the parties agreed to in writing. Any remaining claims shall not be filed or be deemed filed in arbitration, nor shall any arbitration fees be assessed in connection with those claims unless and until they are selected to be filed in individual arbitration proceedings as part of a staged process. Following this third set of staged proceedings, counsel for claimants may elect to have the parties participate in a global mediation session of all remaining claims with a retired federal or state court judge.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            If your claim is not resolved as part of the staged process identified above, either:
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Option One: You and Cnnex Limited Company may separately or by agreement, opt out of arbitration and elect to have your claim heard in court consistent with these Terms. You may opt out of arbitration by sending Cnnex Limited Company your individual, personally signed notice of your intention to opt out by certified mail addressed to Cnnex Limited Company, 701 Tilery Street #12, Austin, Texas 78702, Attn: Legal Department. Such an opt out notice must be sent by you personally, and not by your agent, attorney, or anyone else purporting to act on your behalf. It must include a statement, personally signed by you, that you wish to opt out of arbitration within 30 days after the conclusion of Stage 3 or the elective mediation associated with Stage 3. Cnnex Limited Company may opt your claim out of arbitration by sending an individual, personally signed notice of its intention to opt out to your counsel within 14 days after the expiration of your 30-day opt out period. Counsel for the parties may agree to adjust these deadlines.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            OR
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            Option Two: If neither you nor Cnnex Limited Company elect to have your claim heard in court consistent with Option One, then you agree that your claim will be resolved as part of continuing, staged individual arbitration proceedings as set forth below. Assuming the number of remaining claims exceeds 200, then 200 claims shall be randomly selected (or selected through a process agreed to by counsel for the parties) to be filed and to proceed in individual arbitrations as part of a staged process. If the number of remaining claims is fewer than 200, then all of those claims shall be filed and proceed in individual arbitrations. Any remaining claims shall not be filed or be deemed filed in arbitration, nor shall any arbitration fees be assessed in connection with those claims unless and until they are selected to be filed in individual arbitration proceedings as part of a staged process. After each set of 200 claims are adjudicated, settled, withdrawn, or otherwise resolved, this process shall repeat consistent with these parameters. Counsel for the parties are encouraged to meet and confer, participate in mediation, and engage with each other and with NAM (including through a Procedural Arbitrator, as that term is used in the NAM Rules) to explore ways to streamline the adjudication of claims, increase the number of claims to proceed at any given time, promote efficiencies, conserve resources, and resolve the remaining claims.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            A court of competent jurisdiction shall have the authority to enforce these Mass Filing provisions and, if necessary, to enjoin the mass filing, prosecution, or administration of arbitrations and the assessment of arbitration fees. If these additional procedures apply to your Claim, and a court of competent jurisdiction determines that they are not enforceable as to your Claim, then your Claim shall proceed in a court of competent jurisdiction consistent with these Terms.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            You and Cnnex Limited Company agree that we each value the integrity and efficiency of arbitration and wish to employ the process for the fair resolution of genuine and sincere disputes between us. You and Cnnex Limited Company acknowledge and agree to act in good faith to ensure the processes set forth herein are followed. The parties further agree that application of these Mass Filing procedures have been reasonably designed to result in an efficient and fair adjudication of such cases.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            1.7	Severability
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"}>
            If any portion of this Arbitration Agreement is found to be void, invalid, or otherwise unenforceable, then that portion shall be deemed to be severable and, if possible, superseded by a valid, enforceable provision, or portion thereof, that matches the intent of the original provision, or portion thereof, as closely as possible. The other portions of this Arbitration Agreement shall continue to be enforceable and valid according to the terms contained herein.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            2. Service Purpose and Usage Restrictions
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            2.1 The downloading tools provided by this Website are for personal, non-commercial use only.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            2.2 It is strictly prohibited to use downloaded content for any commercial purposes, public display, distribution, or any activities that violate applicable laws.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            2.3 Users must ensure they have the legal right to download the relevant content and bear all legal responsibilities arising from any violation of this provision.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            2.4 You may not transfer or copy any Content from the wireless device on which you originally received Content to any other device, including, without limitation, any computer or another wireless device.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            3. Intellectual Property Protection
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            3.1 This Website respects and protects the intellectual property rights of all video platforms and content creators.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            3.2 We strictly comply with the Digital Millennium Copyright Act (DMCA) and other applicable regulations and have implemented a copyright complaint mechanism.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            3.3 Users must comply with the terms of use and copyright regulations of the relevant platforms when using our tools.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            4. Data Protection and Privacy
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            4.1 This Website is committed to protecting user privacy in accordance with [applicable data protection regulations, e.g., GDPR].
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            4.2 We do not store, track, or process any video content downloaded by users.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            4.3 For specific privacy policies, please refer to our “Privacy Policy” page.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            5. Limitation of Liability and Disclaimer
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            5.1 This Website is not responsible for users’ downloading actions or the content downloaded.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            5.2 We are not liable for any direct, indirect, incidental, special, or punitive damages resulting from the use of our services.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            5.3 We are not responsible for losses caused by technical failures, third-party actions, or force majeure.       
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            5.4 All services provided by this Website are on an “as is” basis, without any express or implied warranties.
          </Text>   
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            6. Service Characteristics
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            6.1 Download links are valid for 1 hour after generation and cannot be used after expiration.
          </Text>   
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            6.2 This Website does not store any video files downloaded by users.
          </Text>   
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            7. User Responsibilities
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            7.1 Users must ensure their actions comply with the laws and regulations of their country/region.
          </Text>  
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            7.2 Users are solely responsible for all consequences arising from their use of this service.
          </Text>  
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            8. Use by Minors
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Users under 18 should use this Website’s services under the guidance of a guardian.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            9. Registration and Access Controls
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            If we request registration information from you to set up a user account, you must provide us with accurate and complete information and must update the information when it changes. You may not access any age-restricted Services unless you are above the required age.
          </Text>  
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            You are responsible for maintaining the confidentiality of your user account login names and passwords and must not permit use of your account by anyone other than members of your household. You accept responsibility for all activities, charges, and damages that occur under your account, including use of your account by other members of your household, and unauthorized use of your account. If you have reason to believe that someone is using your account without your permission, you should contact us immediately. We are not responsible for any loss or damage resulting from unauthorized use.
          </Text>  
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            10. Third Party Content
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            We may provide third party content (including advertisements) or link to third party websites on the Services. We do not necessarily endorse or evaluate third party content and websites, and we do not assume responsibility for third parties’ actions or omissions. In certain instances, you may be able to connect and/or link your account to certain third-party social media sites (“Social Media Sites”), including, without limitation, Facebook and Twitter. If you connect with us through a social media platform or navigate to a social media platform from one of our services, the social media platform will collect your information separately from us. You should review third party site and platform terms of service and privacy policies to understand how they are using your information and your rights in relation to such information.
          </Text>  
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            11. Access to Services and Accounts
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            We may take any of the following actions in our sole discretion at any time, and without giving you prior notice:
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Change or discontinue the Services. 
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Change how we offer and operate Services (e.g., to begin charging a fee to access features or Content that we previously made available without charge).
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Remove Content from the Services.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Restrict, suspend or terminate your access to one or more Services or features thereof.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Deactivate your accounts and delete all related information and files in your accounts.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            We will not be liable to you or any third party for taking any of these actions and we will not be limited to the remedies above if you violate these Terms. If we terminate your access to any of the Services, you must immediately stop using such Service. However, if you have paid for a subscription to fee-based Service, and we discontinue the Service before the end of a paid subscription period, or we terminate your account before the end of a paid subscription period for reasons other than your breach of these Terms, we will refund a prorated portion of the applicable subscription fee corresponding to the portion of the paid subscription period for which our action caused you not to have access to the relevant Service. If we terminate your access to fee-based Service because you breached these Terms, you will not be entitled to any refund.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            12. Indemnification
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            You will defend, indemnify and hold harmless Cnnex Limited Company, its affiliates, and their respective directors, officers, employees, shareholders, vendors, partners, contractors, agents, licensors or other representatives and all of their successors and assigns (collectively, the “Cnnex Limited Company Parties”) with respect to all third party claims, costs (including attorney’s fees and costs), damages, liabilities, and expenses or obligations of any kind, arising out of or in connection with your use or misuse of the Services (including, without limitation use of your account, whether or not authorized by you, and claims arising from User Submissions). Cnnex Limited Company retains the right to assume the exclusive defense and control of any claim subject to indemnification, and in such cases, you agree to cooperate with us to defend such claim. You may not settle any claim covered by this Section 12 without Cnnex Limited Company’s prior written approval.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            13. Amendment of Terms
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            13.1 This Website reserves the right to modify this disclaimer at any time without prior notice.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            13.2 Modified terms will be published on this page, and it is the user’s responsibility to check for updates.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            13.3 Continued use of this Website constitutes acceptance of the updated terms.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            14. Governing Law, Venue, and Jurisdiction
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            These Terms and all claims arising from or related to your use of the Services will be governed by and construed in accordance with the laws of the State of New York, except New York's conflict of law rules. These Terms will not be governed by the United Nations Convention on Contracts for the International Sale of Goods, if applicable.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            With respect to any disputes or claims not subject to arbitration or small claims court (as set forth in Section 1 above), you agree to exclusive jurisdiction in the state and federal courts in Bellevue, Washington.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            Regardless of any statute or law to the contrary, you must file any claim or action related to use of the Services or these Terms within one year after such claim or action accrued. Otherwise, you will waive the claim or action.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            15. Entire Agreement
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            This disclaimer constitutes the entire agreement between users and this Website regarding the use of the service and supersedes any prior oral or written agreements.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            16. Severability
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            If any provision of this disclaimer is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            17. Disclaimers; Limitation of Liability
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            THE CNNEX LIMITED COMPANY PARTIES DO NOT REPRESENT OR WARRANT: (1) THAT THE SERVICES, ANY OF THE SERVICES’ FUNCTIONS OR ANY CONTENT WILL BE UNINTERRUPTED OR FREE OF ERRORS OR OMISSIONS; (2) THAT DEFECTS WILL BE CORRECTED; (3) THAT THE SERVICES OR THE SERVERS HOSTING THEM ARE FREE OF VIRUSES OR OTHER HARMFUL CODE; OR (4) THAT THE SERVICES OR INFORMATION AVAILABLE THROUGH THE SERVICES WILL CONTINUE TO BE AVAILABLE. THE CNNEX LIMITED COMPANY PARTIES SHALL HAVE NO LIABILITY FOR ANY SUCH ISSUES. THE CNNEX LIMITED COMPANY PARTIES DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND AS TO QUALITY, AVAILABILITY AND SUBJECT MATTER OF CONTENT. THE SERVICES, INCLUDING ALL CONTENT AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH OR SENT FROM THE SERVICES, ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS.”
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            YOUR ACCESS TO AND USE OF THE SERVICES (INCLUDING THEIR FUNCTIONS AND CONTENT) IS AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE SERVICES.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            THE CNNEX LIMITED COMPANY PARTIES WILL NOT BE LIABLE FOR ANY FAILURE OR DELAY IN THEIR PERFORMANCE DUE TO ANY CAUSE BEYOND THEIR REASONABLE CONTROL, INCLUDING ACTS OF WAR, ACTS OF GOD, ACTS OF THIRD PARTIES, EARTHQUAKE, FLOOD, EMBARGO, RIOT, SABOTAGE, LABOR SHORTAGE OR DISPUTE, GOVERNMENTAL ACT, POWER FAILURE OR FAILURE OF THE INTERNET OR COMPUTER EQUIPMENT.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT OF THE CNNEX LIMITED COMPANY PARTIES’ ACTS OR OMISSIONS, THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING OPERATION OF THE SERVICES OR ANY OTHER SERVICE, PROPERTY, PRODUCT, PROGRAM, TELEVISION SHOW, MOTION PICTURE, OR OTHER CONTENT OWNED OR CONTROLLED BY THE CNNEX LIMITED COMPANY PARTIES.
          </Text> 
          <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.700"} >
            18. Miscellaneous
          </Text>
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            We may be required by state or federal law to notify you of certain events. You hereby acknowledge and agree that such notices will be effective upon our posting them in the relevant Service or delivering them to you via email. You may update your email address by visiting the Services where you have provided contact information. If you do not provide us with accurate information, we will not be responsible for failure to notify you. Our failure to exercise or enforce any right or provision in these Terms will not constitute a waiver of such right or provision. These Terms, including all additional terms, conditions, and policies on the Services, constitute the entire agreement between you and us and supersede all prior agreements with respect to the subject matter hereof. Nothing in these Terms affects any non-waivable statutory rights that apply to you. If any part of these Terms is determined to be invalid or unenforceable under applicable law, that provision will be removed, and the remainder of the Terms will continue to be valid and enforceable.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            You authorize us to provide information concerning you and your activities to comply with applicable laws or respond to court order, subpoenas, or other lawful requests, or if we believe doing so would protect your safety or that of another person or protect the security of the Services, or as otherwise described in the Privacy Policy , subject to your right to make certain choices about our use of your personal information as described in the Privacy Policy.
          </Text> 
          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} >
            These Terms will survive any termination of your access to the Services, whether we terminate your access, or you voluntarily discontinue your use.
          </Text> 

          <Text fontSize={"sm"} color={"gray.700"} textAlign={"left"} lineHeight={"2"} fontWeight={'bold'} >
            By using Pickreel.com, you confirm that you have read, understood, and agree to comply with all terms of this legal disclaimer. If you have any questions, please contact our legal department.
          </Text> 
        </Stack>

      </Box>
    </Flex>

  );
};

export default TermsOfUse;