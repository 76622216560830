import { Box, Button, Flex, Input, Stack, Text, Link, Image, Alert, AlertIcon, AlertTitle, Checkbox, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import { MdPersonAdd, MdVisibility, MdVisibilityOff, MdClose } from "react-icons/md"
import { useNavigate } from "react-router-dom";
import { useGlobalData } from '../context/GlobalDataContext';
import { Turnstile } from '@marsidev/react-turnstile';
import { Helmet } from 'react-helmet';
// import axios from 'axios';

const logo = require("../images/pickreel-logo_s2.png");

const SignUpPage = ({ setIsLoginPage }) => {

    const nav = useNavigate();
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    const [ success, setSuccess ] = useState(false);
    const { apiUrl } = useGlobalData();
    const [ termsAccepted, setTermsAccepted ] = useState(false);
    const [ termsError, setTermsError ] = useState('');

    const [turnstileToken, setTurnstileToken] = useState(null);
    const turnstileRef = useRef(null); // 创建 Turnstile 组件的引用



    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleEmailBlur = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            setEmailError('Email is required');
        } else if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    }

    const handlePasswordBlur = () => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;

        if (!password) {
            setPasswordError('Password is required');
        } else if (!passwordRegex.test(password)) {
            setPasswordError('Password must be 8-25 characters long and contain at least one letter, number, special character');
        } else {
            setPasswordError('');
        }
    }

    const handleConfirmPasswordBlur = () => {
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm password is required');
        } else if (confirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    }

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }
        if (!passwordRegex.test(password)) {
            setPasswordError('Password must be 8-25 characters long and contain at least one letter, number, special character');
            return false;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return false;
        }
        if (!termsAccepted) {
            setTermsError('Please accept the terms of use');
            return false;
        }
        if (!turnstileToken) {
            setEmailError('Please complete the CAPTCHA');
            return false;
        }
        setEmailError('');
        setPasswordError('');
        setConfirmPasswordError('');
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {   
            setIsLoading(true);

            // console.log("Form is valid");
            const credentials = `prubasicauth,${email},${password}`;
            const encodedCredentials = btoa(credentials);
            // console.log(encodedCredentials);
            try {
                const response = await fetch(`${apiUrl}/new_user`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        basic: encodedCredentials,
                        turnstileToken: turnstileToken // 添加 turnstileToken
                    }),
                });
                const data = await response.text();
                // console.log(data);
                if (data.includes("Existed User")) {
                    setEmailError('User already exists');
                    turnstileRef.current.reset();
                    return
                } else if (data.includes("CAPTCHA verification failed")) {
                    setEmailError("error CAPTCHA verfication")
                    return
                } else {
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    setSuccess(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        setIsLoginPage(true);
        return () => setIsLoginPage(false);
    }, [setIsLoginPage]);

    const handleTurnstileChange = (token) => {
        setTurnstileToken(token);
    };

    return (
        <Flex
            height="100vh"
            align="center"
            justify="center"
        >
            <Helmet>
                <title>Pickreel - Registration</title>
                <meta name="description" content="Join Pickreel member, enjoying video downloading everywhere everytime, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
                <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
            </Helmet>
            {success && (
                <Alert status="success" position="fixed" top="0" left="0" right="0" zIndex="1000">
                    <AlertIcon />
                    <AlertTitle>Please check your email for verification, otherwise check Spam folder!</AlertTitle>
                </Alert>
            )}
            <Box
                bg="white"
                p={8}
                width={screenWidth > 768 ? "lg" : "90%"}
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                marginBottom={"100px"}
                position={"relative"}
            >
                <IconButton 
                    icon={<MdClose />}
                    onClick={() => nav("/")}
                    variant={"ghost"}
                    position={"absolute"}
                    top={2}
                    right={2}
                    aria-label='Close'
                />
                <Image src={logo} width="60px" cursor={"pointer"} onClick={() => {
                    nav("/");
                }} />
                <Stack spacing={4}>
                    <Text fontSize="2xl" fontWeight="bold">Sign up for Pickreel</Text>

                    <Box>
                        <Input placeholder="Email" 
                                type="email" 
                                size="lg" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                onBlur={handleEmailBlur}   />
                        {emailError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{emailError}</Text>}
                    </Box>

                    <Box>
                        <InputGroup size="lg">
                            <Input placeholder="Password" 
                                type={showPassword ? "text" : "password"} 
                                size="lg" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                onBlur={handlePasswordBlur} />
                            <InputRightElement  height={"100%"}>
                                <IconButton
                                    icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    onClick={() => setShowPassword(!showPassword)}
                                    variant="ghost"
                                    aria-label="Toggle Password Visibility"
                                    size="sm"
                                    height={"1.75rem"}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {passwordError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{passwordError}</Text>}
                    </Box>

                    <Box>
                        <InputGroup size="lg">
                            <Input placeholder="Confirm Password" 
                                    type={showConfirmPassword ? "text" : "password"} 
                                    size="lg" 
                                    value={confirmPassword} 
                                    onChange={(e) => setConfirmPassword(e.target.value)} 
                                    onBlur={handleConfirmPasswordBlur} />
                            <InputRightElement  height={"100%"}>
                                <IconButton
                                    icon={showConfirmPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    variant="ghost"
                                    aria-label="Toggle Password Visibility"
                                    size="sm"
                                    height={"1.75rem"}
                                />
                            </InputRightElement>
                        </InputGroup>

                        {confirmPasswordError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{confirmPasswordError}</Text>}
                    </Box>  

                    <Box>
                        <Checkbox
                            isChecked={termsAccepted}
                            onChange={(e) => {
                                setTermsAccepted(e.target.checked);
                                if (e.target.checked) {
                                    setTermsError('');
                                }
                            }}
                            colorScheme="green"
                        >
                            I accept the <Link color="blue.500" href="/terms">terms and conditions</Link>
                        </Checkbox>
                        {termsError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{termsError}</Text>}
                    </Box>

                    <Turnstile
                        siteKey='0x4AAAAAAAluzucTu63IzIuJ'
                        onSuccess={handleTurnstileChange} // 确保传递参数
                        ref={turnstileRef} // 绑定 Turnstile 组件的引用
                    />

                    <Stack spacing={4}>
                        <Flex justify="space-between">
                            <Link color="blue.500" onClick={() => {
                                nav("/login");
                            }}>Sign in</Link>
                            <Button leftIcon={<MdPersonAdd />} 
                                    colorScheme="green" 
                                    size="sm" 
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    >Sign up</Button>
                        </Flex>
                    </Stack>
                </Stack>
            </Box>
        </Flex>
    )
}

export default SignUpPage;