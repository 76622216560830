import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, Input, Stack, Text, Alert, AlertIcon, useToast, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalData } from '../context/GlobalDataContext';
import { MdVisibility, MdVisibilityOff, MdClose } from "react-icons/md"
import { Turnstile } from '@marsidev/react-turnstile';
import { Helmet } from 'react-helmet';

const ResetPasswordPage = () => {
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ token, setToken ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const toast = useToast();
    const location = useLocation();
    const nav = useNavigate();
    const { apiUrl } = useGlobalData();
    const [ turnstileToken, setTurnstileToken ] = useState(null);
    const turnstileRef = useRef(null);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const encodedEmail = params.get('em');
        const token = params.get('token');
        if (encodedEmail && token) {
            setEmail(atob(encodedEmail));
            setToken(token);
        } else {
            setError('Invalid reset link');
        }
    }, [location.search]);

    const handlePasswordBlur = () => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;

        if (!password) {
            setPasswordError('Password is required');
        } else if (!passwordRegex.test(password)) {
            setPasswordError('Password must be 8-25 characters long and contain at least one letter, number, special character');
        } else {
            setPasswordError('');
        }
    }

    const handleConfirmPasswordBlur = () => {
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm password is required');
        } else if (confirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    }

    const validateForm = () => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
        if (!passwordRegex.test(password)) {
            setError('Password must be 8-25 characters long and include at least one letter, one number, and one special character');
            return false;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return false;
        }
        if (!turnstileRef) {
            setError('Please complete the CAPTCHA');
            return false;
        }
        setError('');
        setConfirmPassword('');
        setPassword('');
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm) {
            const credentials = `prubasicauth,${email},${password}`;
            const encodedCredentials = btoa(credentials);

            try {
                setIsLoading(true);
                const response = await fetch(`${apiUrl}/reset_pw?token=${token}`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        basic: encodedCredentials,
                        turnstileToken: turnstileToken,
                    }),
                });

                const data = await response.text();
                // console.log(data);

                if (data.includes("expired")) {
                    toast({
                        title: "Error",
                        description: "Failed to reset password. The link may have expired.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    turnstileRef.current.reset();
                    return
                } else if (data.includes("CAPTCHA verification failed")) {
                    toast({
                        title: "Error",
                        description: "Failed to verify CAPTCHA.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                    turnstileRef.current.reset();
                } else {
                    toast({
                        title: "Password reset successful",
                        description: "You can now log in with your new password.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    nav('/login');
                }
            } catch (error) {
                console.log(error);
                toast({
                    title: "Error",
                    description: "An error occurred while resetting the password.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleTurnstileChange = (token) => {
        setTurnstileToken(token);
    };

    return (
        <Flex
            height={"100vh"}
            align={"center"}
            justify={"center"}
            direction={"column"}
        >
            <Helmet>
                <title>Pickreel - Reset Password</title>
                <meta name="description" content="Reset password for video downloading, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
                <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
            </Helmet>
            <Box
                bg={"white"}
                p={8}
                width={screenWidth > 768 ? "lg" : "sm"}
                borderWidth={1}
                borderRadius={8}
                boxShadow={"lg"}
                position={"relative"}
            >
                <IconButton 
                    icon={<MdClose />}
                    onClick={() => nav("/")}
                    variant={"ghost"}
                    position={"absolute"}
                    top={2}
                    right={2}
                    aria-label='Close'
                />
                <Stack spacing={4}>
                    <Text fontSize={"2xl"} fontWeight={"bold"}>Reset Password</Text>
                    {error & (
                        <Alert status='error'>
                            <AlertIcon />
                            {error}
                        </Alert>
                    )}
                    <Box>
                        <InputGroup size="lg">
                            <Input
                                placeholder='New Password'
                                type={showPassword ? "text" : "password"} 
                                size={'lg'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={handlePasswordBlur}
                            />
                            <InputRightElement  height={"100%"}>
                                <IconButton
                                    icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    onClick={() => setShowPassword(!showPassword)}
                                    variant="ghost"
                                    aria-label="Toggle Password Visibility"
                                    size="sm"
                                    height={"1.75rem"}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {passwordError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{passwordError}</Text>}
                    </Box>
                    <Box>
                        <InputGroup size={'lg'}>
                            <Input
                                placeholder='Confirm Password'
                                type={showConfirmPassword? "text": "password"}
                                size={'lg'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onBlur={handleConfirmPasswordBlur}
                            />
                            <InputRightElement height={"100%"}>
                                <IconButton
                                    icon={showConfirmPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    variant={'ghost'}
                                    aria-label='Toggle Confirm Password Visibility'
                                    size={"sm"}
                                    height={"1.75rem"}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {confirmPasswordError && <Text marginTop="0px" marginLeft="5px" color="red.500" size="sm">{confirmPasswordError}</Text>}
                    </Box>

                    <Turnstile
                        siteKey='0x4AAAAAAAluzucTu63IzIuJ'
                        onSuccess={handleTurnstileChange} // 确保传递参数
                        ref={turnstileRef} // 绑定 Turnstile 组件的引用
                    />

                    <Button colorScheme='green' size={'lg'} onClick={handleSubmit} isLoading={isLoading}>Submit</Button>
                    <Text color={"gray.500"}>The link is valid for 5 minutes.</Text>
                </Stack>
            </Box>
        </Flex>
    );
};

export default ResetPasswordPage;