
import { Button, HStack, Input, VStack, Image, Text, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import { MdDownload, MdSearch } from "react-icons/md"
import { useState, useEffect } from 'react';
import VideoList from './VideoList';
import { useNavigate } from 'react-router-dom';
import { useGlobalData } from '../context/GlobalDataContext';
import { Helmet } from 'react-helmet';
// import axios from 'axios';

const mainLogo = require("../images/pickreel-logo_s2.png");
const youtubeLogo = require("../images/youtubelogo.png");
const xiaohongshuLogo = require("../images/xiaohongshulogo.png");
// const douyinLogo = require("../images/douyinlogo.png");
const threadLogo = require("../images/threadslogo.png");
const tiktokLogo = require("../images/douyinlogo.png");
const instagramLogo = require("../images/instagramlogo.png");
const xLogo = require("../images/xlogo.png");
const facebookLogo = require("../images/facebooklogo.png");
const linkedinLogo = require("../images/linkedinlogo.png");
const biliLogo = require("../images/Bililogo.png");
const kuaishouLogo = require("../images/kuaishou.png")
// const reverbnationLogo = require("../images/reverbnation.png");
// const spotifyLogo = require("../images/spotify.png");
// const soundcloudLogo = require("../images/soundcloud.png");
// const bandcampLogo = require("../images/bandcamp.png");
// const applemusicLogo = require("../images/applemusic.png");


// const baseUrl = "http://192.168.4.200:8081"

const SearchBody=() => {
    const nav = useNavigate();
    const { baseUrl, isLoggedIn } = useGlobalData();
    const [ addr, setAddr ] = useState('');
    const [ output, setOutput ] = useState('');
    const [ videoUrl, setVideoUrl ] = useState('');
    const [ run, setRun ] = useState(false);
    // const [ isLoggedIn, setIsLoggedIn ] = useState(false);

    const [ downloadProgress, setDownloadProgress ] = useState(0);
    const [ isDownloading, setIsDownloading ] = useState(false); // Track downloading state
    const [ fileSize, setFileSize ] = useState(0);
    const [ videoString, setVideoString ] = useState("")

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    // const [ error, setError ] = useState('');
    const toast = useToast();

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
      // if exists load the address from localStorage
      const savedAddress = localStorage.getItem("v-address");
      if (savedAddress) {
        setAddr(savedAddress);
      }
    }, []);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!isLoggedIn) {
        nav('/login');
        return;
      }
      setRun(true);
      setOutput('');
      setDownloadProgress(0);
      setVideoString("");
      localStorage.removeItem('v-address');
      try {
        const response = await fetch(`${baseUrl}/xhs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ addr: addr })
        });
        const data = await response.text();
        setOutput(data);
  
        console.log(data);
        const parseStr = data.split('\n');
        console.log(parseStr);
        if (parseStr.length <= 2) {
            setVideoUrl('No video URL found, maybe your link was expired or network issue, you can try again.')
            setRun(false);
        } else {
            if (addr.includes("http://xhslink.com") || addr.includes("https://www.xiaohongshu.com")) {
              setVideoUrl(parseStr[1].split('?')[0]);
              setVideoString(parseStr[1].split('?')[0]);
            } else if (addr.includes("threads.net")) {
              setVideoString(parseStr[1]);
            } else if (!addr.includes("douyin.com")) {
              setVideoString(parseStr[1]);
              setVideoUrl(parseStr[1]);
            }   
            // console.log(parseStr[2]);
            if (addr.includes("douyin.com")) {
                setFileSize(parseStr[2]);
                setVideoUrl(parseStr[1]);
            }
            setAddr('');
            setRun(false);
        }
      } catch (error) {
        setOutput('Error: ' + error.message);
        // setError('Error: ' + error.message);
        toast({
          title: "Net work error",
          description: `${error.message}, please try again later.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setRun(false);
      }
    }

    const handleDownloadPrfile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl
        link.download = "prvideo.mp4";
        link.target = '_blank';                 // Ensure it's downloaded, not opened in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDownloadDy = async (videoUrl) => {
        try {
            setIsDownloading(true); // Start the download progress indicator
      
            // Step 1: Make the request to your Go server
            const response = await fetch(`https://prfile.heasor.com:8989/download-video?videoUrl=${encodeURIComponent(videoUrl)}`);
            
            if (!response.ok) {
              throw new Error(`Failed to download file: ${response.statusText}`);
            }
      
            // Step 2: Read the response stream
            const reader = response.body.getReader();
            let receivedLength = 0;
            const chunks = []; // Store received data chunks
      
            while (true) {
              const { done, value } = await reader.read();
      
              if (done) {
                break;
              }
      
              chunks.push(value); // Push the chunk of data received
              receivedLength += value.length;
              setDownloadProgress((receivedLength / fileSize) * 100);

            }
      
            // Step 3: Combine the chunks into a Blob
            const blob = new Blob(chunks);
      
            // Step 4: Create a link element and trigger a download
            const blobUrl = window.URL.createObjectURL(blob); // Create a URL for the Blob
            const link = document.createElement('a');
            link.href = blobUrl;
            const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace(/\..+/, '');

            link.setAttribute('download', `prvideo_${timestamp}.mp4`); // Name of the file to download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up the link element
      
            // Revoke the Blob URL after download to free up memory
            window.URL.revokeObjectURL(blobUrl);
      
            // Reset the download state after completion
            setIsDownloading(false);
          } catch (error) {
            console.error('Error downloading the video:', error);
            setIsDownloading(false); // Reset state even on error
          }
    };
    

    const handleDownload = async (fileUrl) => {
        // setDownloadProgress(0);

        if (fileUrl.includes("https://prfile.heasor.com/v/download")) {
            // handleDownloadDy(fileUrl);
            handleDownloadPrfile(fileUrl);
        } 
        else if (fileUrl.includes("https://v5-dy")) {
            handleDownloadDy(fileUrl);
        } 
        else {
            try {
              setIsDownloading(true);
              const response = await fetch(fileUrl);
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              } 

              const total = parseInt(response.headers.get('Content-Length'), 10);
              
              const reader = response.body.getReader();
              let receivedLength = 0;
              const chunks = [];
  
              while (true) {
                  const { done, value } = await reader.read();
                  if (done) break;
  
                  chunks.push(value);
                  receivedLength += value.length;
  
                  // Update progress (percentage)
                  setDownloadProgress((receivedLength / total) * 100);
              }

              // Combine all chunks into a single Uint8Array
              const blobArray = new Uint8Array(receivedLength);
              let position = 0;
              for (let chunk of chunks) {
                  blobArray.set(chunk, position);
                  position += chunk.length;
              }
  
              // Combine chunks into a blob
              // const blob = new Blob(chunks);
              const blob = new Blob([blobArray], { type: 'video/mp4' });

              const url = window.URL.createObjectURL(blob);

              const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace(/\..+/, '');
  
              // Create a temporary link for downloading the file
              const link = document.createElement('a');
              link.href = url;
              link.download = `prvideo_${timestamp}.mp4`; // File name
              document.body.appendChild(link);
              link.click();
  
              // Clean up after download
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
              return

                // setIsDownloading(false);
    
            } catch (error) {
                console.error("Error downloading file:", error);
                return
                // setIsDownloading(false);
            } finally {
              setIsDownloading(false);
            }
        }

    };
  
    return (
      <div className="App">
        <Helmet>
            <title>Pickreel Home - Search and Download Video</title>
            <meta name="description" content="Learn more about Cnnex Group, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
            <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
        </Helmet>
        <VStack width={"100%"} marginBottom={"60px"}>
            <Image src={mainLogo} width={"180px"} alt='main logo' cursor={"pointer"} onClick={() => {
                nav("/");
            }} />
            {/* <Stack direction={"row"} spacing={4} mt={4} marginLeft={"10px"} marginTop={"30px"}>
                <Image src={youtubeLogo} alt="YouTube" boxSize="30px" />
                <Image src={linkedinLogo} alt="LinkedIn" boxSize="30px" />
                <Image src={tiktokLogo} alt="TikTok" boxSize="30px" />
                <Image src={facebookLogo} alt="Facebook" boxSize="30px" />
                <Image src={instagramLogo} alt="Instagram" boxSize="30px" />
                <Image src={xLogo} alt="X.com" boxSize="30px" />
                <Image src={threadLogo} alt="Thread" boxSize="30px" />
                <Image src={xiaohongshuLogo} alt="小红书" boxSize="30px" />
                <Image src={biliLogo} alt='bilibili' boxSize={"30px"} />
            </Stack> */}
            <Wrap spacing={4} ml="10px" mt="30px" justify="center" >
            <WrapItem>
                <Image src={youtubeLogo} alt="YouTube" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={linkedinLogo} alt="LinkedIn" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={tiktokLogo} alt="TikTok" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={facebookLogo} alt="Facebook" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={instagramLogo} alt="Instagram" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={xLogo} alt="X.com" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={threadLogo} alt="Thread" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={xiaohongshuLogo} alt="小红书" boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={biliLogo} alt='bilibili' boxSize="30px" />
            </WrapItem>
            <WrapItem>
                <Image src={kuaishouLogo} alt='kuaishou' boxSize="30px" />
            </WrapItem>
        </Wrap>
            <Text marginTop={"10px"} color={'gray'} >more ...</Text>
            <HStack spacing={5} width={screenWidth > 768 ? "80%" : "90%"} marginTop={"20px"}>
                <Input placeholder={screenWidth > 768 ? 'Enter video address copied from your favorite website' : 'Enter video address'} 
                       height={"50px"} 
                       type='text'
                       value={addr}
                       onChange={(e) => {
                        setAddr(e.target.value);
                        localStorage.setItem('v-address', e.target.value);
                       }}
                       />
                <Button leftIcon={<MdSearch size={screenWidth > 768 ? "20px" : "30px"}/>} 
                        style={{backgroundColor: run || addr === "" ? "gray" : "#972301", cursor: run || addr === "" ? "default" : "pointer"}}
                        color={ run || addr === "" ? "lightgray" : "white"}
                        variant='solid' 
                        height={screenWidth > 768 ? "50px" : "45px"}
                        width={screenWidth > 768 ? "100px" : "80px"}
                        disabled={run || addr===""}
                        onClick={handleSubmit}
                        size={screenWidth > 768 ? "sm" : "md"}
                        isLoading={run}
                        > {screenWidth > 768 ? "Search" : ""} </Button>
            </HStack>
            {
                output && videoString === "" && output.includes("http") && (

                    <VStack width={"80%"} display={"flex"}  justifyContent={"center"} spacing={6}>
                        <Button leftIcon={<MdDownload />} 
                                style={{backgroundColor: "#972301", cursor: "pointer"}}
                                color={"white"}
                                variant='solid' 
                                height={"50px"}
                                disabled={run || addr===""}
                                onClick={() => handleDownload(videoUrl)}
                                marginTop={"15px"}
                                // fontSize={"10px"}
                                isLoading={isDownloading}
                                
                        > Download </Button>
                        {downloadProgress > 0 && <p>Download progress: {Math.round(downloadProgress)}%</p>}
                        {/* {isDownloading && <p>Downloading...</p>} */}

                    </VStack>
                )
            }
            {videoString !== "" &&
              <VideoList videoString={videoString} />
            }

            {/* <VideoList videoString={"https://prfile.heasor.com/v/download?num=tk_output_20240920164142"} /> */}
        </VStack>
      </div>
    );

}

export default SearchBody;