import { createContext, useState, useContext } from "react";

export const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
    const [ token, setToken ] = useState('');
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);

    return (
        <GlobalDataContext.Provider 
            value={{ token, setToken,
                isLoggedIn, setIsLoggedIn,
                // baseUrl: "https://prsrvapp.heasor.com:8143",
                apiUrl: "https://prsrva.heasor.com:8141/api",
                baseUrl: "https://prmainapp.heasor.com:8443",
             }}>
            {children}
        </GlobalDataContext.Provider>
    );
}

export const useGlobalData = () => useContext(GlobalDataContext);