import React from "react";
import "./Header.css";
import { Button, Menu, MenuButton, MenuList, MenuItem, Avatar, Link } from "@chakra-ui/react";
// import { FaSun, FaMoon } from "react-icons/fa";  
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { MdLogin } from "react-icons/md";
import { useGlobalData } from '../context/GlobalDataContext';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Header = ({ isLoginPage, avatar }) => {
    const { isLoggedIn, setIsLoggedIn } = useGlobalData();

    const nav = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('pr-token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    // eslint-disable-next-line
    }, []);

    const getInitial = () => {
        const email = localStorage.getItem('pr-email');
        if (email) {
            return email.charAt(0).toUpperCase();
        }
        return '';
    }

    const getColor = (initial) => {
        const colors = [
            'red.500', 'orange.500', 'yellow.500', 'green.500', 'teal.500', 
            'blue.500', 'cyan.500', 'purple.500', 'pink.500', 'gray.500'
        ];
        const charCode = initial.charCodeAt(0);
        return colors[charCode % colors.length];
    };
    const initial = getInitial();
    const bgColor = getColor(initial);

    return (
        <header className="header">
            <nav className="header__nav">
                <ul className="header__nav-list">
                    {/* <Image src={logo} width={"30px"} cursor={"pointer"} alt='logo' onClick={() => {
                        nav("/");
                    }} /> */}
                    <li className="header__nav-item">
                        <a href="/about" className="header__nav-lin">About</a>
                    </li>
                    <li className="header__nav-item">
                        <a href="/terms" className="header__nav-link">Terms</a>
                    </li>
                </ul>
                <div className="header__right">
                {/* <button onClick={toggleTheme} className="theme-toggle-btn">
                                {isDarMode ? <FaSun /> : <FaMoon />}
                            </button> */}
                {isLoggedIn ? (
                        <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                            <Avatar size="sm" name={initial} src={avatar} bg={avatar ? 'transparent' : bgColor} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem>
                                <Link href="/settings">Settings</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link onClick={() => {
                                    localStorage.removeItem('pr-token');
                                    localStorage.removeItem('pr-email');
                                    localStorage.removeItem('pr-avatar');
                                    setIsLoggedIn(false);
                                    nav("/");
                                }}>Log out</Link>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                        
                    ) : !isLoginPage && (
                        <Button onClick={() => nav("/login")} 
                                colorScheme="green" 
                                size="sm"
                                leftIcon={<MdLogin />}>Sign in</Button>
                    )}
                    {/* <button className="header__menu-icon">☰</button> */}
                </div>
            </nav>
        </header>
    );
};

export default Header;