import React from 'react';
import { Text, Stack } from '@chakra-ui/react';

import FullScreenSection from './FullScreenSection';

const SupportPage = () => {
  return (
    <FullScreenSection>
        <Text marginTop={"50px"} fontSize={"3xl"} fontWeight={"bold"} color={"teal.600"} >
            Support
        </Text>

        <Stack marginTop={9} spacing={7} textAlign={"start"} width={"100%"}>
            <Text fontSize={"2xl"} fontWeight={"bold"} >
                Contact Us
            </Text>
            <Text fontSize={"md"}>
             If you have any questions or need assistance, feel free to reach out to us through the following contact details:
            </Text>
            <Text fontSize={"lg"}>
                Email: <a href="mailto:support@example.com" style={styles.link}> service@cnnex.us</a>
            </Text>
            <Text fontSize={"lg"}>
                Telephone: <a href="tel:+17694862551" style={styles.link}>+1 (769) 486-2551</a>
            </Text>
            <Text fontSize={"lg"}>
                Address:
            </Text>
            <Text marginLeft={"30px"} fontSize={"md"}>
                <p>701 Tilery Street<br />Austin, Texas, 78702<br />US</p>
            </Text>
        </Stack>
      </FullScreenSection>  );
};

// Basic inline styles for layout
const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  section: {
    marginBottom: '40px',
  },
  contactDetails: {
    marginTop: '20px',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default SupportPage;