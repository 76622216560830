import { ChakraProvider, Flex } from '@chakra-ui/react';
import './App.css';
import React from 'react';
import SearchBody from './components/SearchBody';
import Header from './components/Header';
import { Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react';
import TermsOfUse from './components/TermsOfUse';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import { GlobalDataProvider } from './context/GlobalDataContext';
import SettingsPage from './components/SettingsPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import NotFound from './components/NotFound';
import SPCPolicy from './components/SPCPolicy';
import SupportPage from './components/SupportPage';
// import { useGlobalData } from './context/GlobalDataContext';


function App() {

  const [ isLoginPage, setIsLoginPage ] = useState(false);
  const [ avatar, setAvatar ] = useState(null);


  useEffect(() => {
    const storedAvatar = localStorage.getItem('pr-avatar');
    if (storedAvatar) {
      setAvatar(storedAvatar);
    }
  }, []);

  return (
    <ChakraProvider>
      <GlobalDataProvider>
      <Flex direction={"column"} minHeight={"100vh"}>
        <Header isLoginPage={isLoginPage} avatar={avatar} />
        <Routes>
          <Route path='/terms' element={<TermsOfUse />}></Route>
          <Route path='/privacy' element={<SPCPolicy />}></Route>
          <Route path='/support' element={<SupportPage />}></Route>
          <Route path='/' element={<SearchBody />}></Route>
          <Route path='/login' element={<LoginPage setIsLoginPage={setIsLoginPage} setAvatar={setAvatar}/>}></Route>
          <Route path='/login/success_verf' element={<LoginPage setIsLoginPage={setIsLoginPage} showSuccess={true} />}></Route>
          <Route path='/signup' element={<SignUpPage setIsLoginPage={setIsLoginPage} />}></Route>
          <Route path='/settings' element={<SettingsPage setAvatar={setAvatar} avatar={avatar}/>}></Route>
          <Route path='/reset' element={<ResetPasswordPage />}></Route>
          <Route path='/about' element={<AboutPage />}></Route>
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </Flex>
      </GlobalDataProvider>
    </ChakraProvider>
  );
}

export default App;
