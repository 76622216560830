import React from 'react';
import { Box, Text, Flex, Link } from '@chakra-ui/react';

const Footer = () => {
    return (
        <Box bg="gray.800" color="white" py={4}>
            <Flex direction={["column", "row"]} align="center" justify="center" fontSize={"sm"} px={8}>
                <Link href="mailto:service@cnnex.us" order={[-1, 0]} mb={[2, 0]}>
                    service@cnnex.us
                </Link>
                <Flex flex="1" justify="center" order={[0, 0]} mb={[2, 0]}>
                    <Text fontSize={"xs"}>&copy; {new Date().getFullYear()} Cnnex Limited Company. All rights reserved.</Text>
                </Flex>
                <Box width={['auto', '150px']} /> 
            </Flex>
        </Box>
    );
};

export default Footer;