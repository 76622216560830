import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Stack, IconButton, Avatar, Input, useToast, Flex } from '@chakra-ui/react';
import { MdClose, MdPassword, MdPhotoCamera } from 'react-icons/md';
import { useGlobalData } from '../context/GlobalDataContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SettingsPage = ({ avatar, setAvatar }) => {  

    const { apiUrl } = useGlobalData();
    // const [ avatar, setAvatar ] = useState(null);
    const toast = useToast();
    const email = localStorage.getItem('pr-email');
    const nav = useNavigate();
    const [ isLoading, setIsLoading ]= useState(false);
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    const uploadAvatar = async (avatarData, email) => {
        const formData = new FormData();
        formData.append("avatar", avatarData);
        try {
            const response = await fetch(`${apiUrl}/upload_avatar?em=${email}`, {
                method: "POST",
                body: formData
            });

            // const data = await response.text();
            // console.log(data);                              

            if (response.ok) {
                toast({
                    title: "Avatar uploaded",
                    description: "Your avatar has been uploaded successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({                 
                    title: "Error",
                    description: "Failed to upload avatar",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                toast({
                    title: "File too large",
                    description: "The selected file is larger than 5 MB.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const avatarData = e.target.result;
                setAvatar(avatarData);
                localStorage.setItem('pr-avatar', avatarData);
                uploadAvatar(file, email);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePasswordReset = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${apiUrl}/req_email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "req-type": "rp",
                    "email": email,
                })
            });

            const data = await response.text();
            // console.log(data);

            if (response.ok) {
                toast({
                    title: "Password reset email sent",
                    description: "Check your email for further instructions.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                if (data.includes("1 minute before")) {
                    toast({
                        title: "Warn",
                        description: "Please wait at least 1 minute before making another reqeust.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                    return
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to send password reset email",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    return
                }
            };
        } catch (error) {  
            console.log(error);
        } finally { 
            setIsLoading(false);
        }
    };

    return (
        <Flex
            height={"100vh"}
            align={"center"}
            justify={"center"}
            direction={"column"}
        >
            <Helmet>
                <title>Pickreel - Account Setting</title>
                <meta name="description" content="Changing pickreel account settings, Network Security, VPN, CyberSecurity, IoT, Internet of Things, downlad videos, download youtue, download facebook, download linkedIn, download Instagram, downland tiktok, download twitter, download X.com, 下载视频，下载抖音，下载小红书，easy download, 下载任意视频, 下载腾讯视频，下载爱奇艺，下载优酷，下载b站." />
                <meta name="keywords" content="Cnnex, IoT, web3.0, healthy, download video, videos, download, VPN, cybersecurity, sensor, youtube, youku, facebook, instagram, linkedin, tiktok, twitter, x, x.com, 下载，视频， 下载抖音，下载小红书，下载腾讯视频，pickreel, pickreel.com, 下载腾讯，下载爱奇艺，下载优酷，下载B站，腾讯视频，爱奇艺、优酷、B站、抖音" />
            </Helmet>
            <Box
                bg="white"
                p={8}
                width={screenWidth > 768 ? "lg" : "sm"}
                borderWidth={1}
                borderRadius={8}
                boxShadow={"lg"}
                position={"relative"}
                marginBottom={"80px"}
            >
                <IconButton 
                    icon={<MdClose />}
                    onClick={() => nav("/")}
                    variant={"ghost"}
                    position={"absolute"}
                    top={2}
                    right={2}
                    aria-label='Close'
                />
                <Stack spacing={4}>
                    <Text fontSize={"2xl"} fontWeight={"bold"}>Settings</Text>
                    <Text fontSize={"md"}>{email.toLowerCase()}</Text>

                    <Flex align={"center"}>
                        <Avatar size={"xl"} src={avatar} />
                        <IconButton
                            icon={<MdPhotoCamera />}
                            onClick={() => document.getElementById(`avatarInput`).click()}
                            variant={"outline"}
                            ml={4}
                        />
                        <Input
                            id="avatarInput"
                            type="file"
                            accept="image/*"
                            onChange={handleAvatarChange}
                            style={{ display: "none" }}
                        />
                    </Flex>
                    <Button colorScheme={"green"} 
                            leftIcon={<MdPassword />}
                            onClick={handlePasswordReset}
                            isLoading={isLoading}
                            >Reset Password</Button>
                </Stack>
            </Box>
        </Flex>
    );
};

export default SettingsPage;
